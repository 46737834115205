.menu .nav-link:nth-child(1) {
  font-weight: bold;
  background-color: #ff5e00;
  border: 2px solid #fff2ff;
  padding: 1rem 0px;
}

.menu .nav-link:nth-child(2),
.menu .nav-link:nth-child(3),
.menu .nav-link:nth-child(4) {
  font-weight: 700;
  background-color: #ff5e00;
  border: 2px solid #fff2ff;
  padding: 1rem 0px;
}


.menu .nav-link:nth-child(5) {
  background-color: #ff5e00;
  border: 2px solid #000;
  padding: 1rem 0px;
}

.nav-link a {
  text-decoration: none;
}

marquee {
  font-size: 14px;
  color: white;
  background-color: #a50438;
  padding: 8px;
  font-weight: bold;
}

.highlight {
  background-color: #ffff00;
}

.game-name {
  font-size: 32px;
  font-weight: bold;
  color: #dc3545;
}

.game-result {
  font-size: 32px;
  font-weight: bold;
  color: #198754;
}

.css-1s7v18u-control {
  border-radius: 10px;
  border: 1px solid #000;
  box-shadow: 0 0 5px #13316c;
  margin-bottom: 5px;
}

.game_column {
  background-color: #f5ecce;
  text-align: center;
  border: 1px solid #000;
  font-weight: bold;
  color: #000;
}

.game_column span {
  font-size: 20px;
}

.game_column h6 {
  text-transform: uppercase;
  color: #f00a3b;
  font-weight: bold;
}

.game_column img {
  padding: 0.8rem 0rem;
}

.ant-table-thead>tr>td,
.ant-table-thead th {
  border-radius: 0px !important;
  border-color: #000;
  background-color: #0000ff !important;
  color: white !important;
  /* font-size: 0.6rem; */
  font-weight: bold;
  text-wrap: nowrap;
}

.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child .ant-table-content {
  border-start-start-radius: 0px !important;
}

.ant-table-content tr {
  /* padding: 5px 0px !important; */
  text-align: center !important;

  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

.ant-table-wrapper .ant-table-container table>tbody>tr>*:first-child {
  background-color: #ffddff !important;
  /* #ffddff */
}

.ant-table-content td,
.ant-table-content th {
  padding: 3px 5px !important;
  text-align: center !important;

  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

.ant-table-content tr:nth-child(even) {
  background-color: #f9f9f9 !important;
}

.ant-table-wrapper table {
  border-radius: 0px !important;
  /* margin-top: -6px; */
}

table tbody tr:hover {
  background-color: #f0f0f0;
  color: red;
}

#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold !important;
}

#go-button {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  cursor: pointer;
}

#go-button:hover {
  background-color: #d43f3a;
}

/* footerButton */
.footerContent {
  padding: 15px;
  border: 3px solid red;
  border-radius: 5px;
  background-color: #fff;
}

.footerButton {
  background-color: #000;
}

.footerButton hr {
  color: #fff;
  opacity: 1;
}

.footerButton .button-group {
  margin: 0px auto;
}

.button-group button {
  margin: 0.5em;
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.button-group button:hover {
  background-color: #d43f3a;
}

.linkGroup a,
.button-group a {
  text-decoration: none;
  color: #fff;
}

.copyright {
  padding-bottom: 5rem;
}

.footerSection {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  border-width: 3px;
  border-color: #0008ff;
  border-style: outset;
  margin: 3px;
  padding: 10px;
  border-radius: 10px;
}

.footerSection h1 {
  font-weight: bold;
  /* text-align: center; */
  color: #f00;
  font-size: 26px !important;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #837e7e;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d43f3a;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e7a9a7;
}

.monthYrTbl {
  text-transform: uppercase;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}

/* toast message */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}

/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #fef9e7;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}




/* footer page style */

.footerStyle {
  background: #171731;
  text-align: center;
  border: 2px solid red;
  border-radius: 10px;
  padding: 10px;
}

.headingStyle {
  font-size: 20px;
  color: white;
  text-transform: uppercase;
}

.paragraphStyle {
  font-size: 10px;
  color: white;
  text-align: justify;
}

.warningStyle {
  font-size: 15px;
  color: white;
  text-align: center;
}

;